<script lang="ts" setup>
import { computed } from "vue";

interface Props {
  disabled?: boolean;
  icon?: boolean;
  loading?: boolean;
  rounded?: boolean;
  block?: boolean;
  outlined?: boolean;
  loadingText?: string;
  color?: string;
  textColor?: string;
  shadow?: boolean;
}

interface ClassButton {
  defaultClass: string;
  bgColor: string;
  textColor: string;
  rounded: string;
  outlined: string;
  icon: string;
  block: string;
  shadow: string;
}

const props = withDefaults(defineProps<Props>(), {
  loadingText: "Please Wait",
  textColor: "text-white",
  color: "bg-red-dark",
});

const classes = computed(() => {
  const classButton: ClassButton = {
    defaultClass:
      "py-1 px-2 rounded-md text-center text-sm transition-all cursor-pointer",
    bgColor: props.color,
    textColor: props.textColor,
    rounded: "",
    outlined: "",
    icon: "",
    block: "",
    shadow: "",
  };
  if (props.block) {
    classButton.block = "w-full block";
  }
  if (props.rounded) {
    classButton.rounded = "!rounded-full !px-3";
  }

  if (props.outlined) {
    classButton.outlined = `hh-outlined border border-${props.color}`;
    classButton.bgColor = "bg-white";
    classButton.textColor = props.color;
  }

  if (props.icon) {
    classButton.icon = "flex justify-center items-center";
  }

  if (props.shadow) {
    classButton.shadow = "shadow-lg";
  }

  const classes = Object.values(classButton);
  return classes;
});

defineOptions({
  name: "HhButton",
});
</script>

<template>
  <button
    class="hh-button"
    v-bind="$attrs"
    :disabled="props.disabled"
    :class="classes"
  >
    <template v-if="!loading">
      <slot>Click Me </slot>
    </template>
    <template v-else>
      <slot name="loader">
        {{ props.loadingText }}
      </slot>
    </template>
  </button>
</template>
<style lang="scss" scoped>
.hh-button {
  &:disabled {
    background: #dee2e4 !important;
    @apply cursor-not-allowed text-white;
  }

  &:hover {
    @apply transition-all;
    filter: brightness(1.06);
  }
  &.hh-outlined {
    &:hover {
      @apply transition-all;
      filter: brightness(0.95);
    }
  }
}
</style>
